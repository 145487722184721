import React from "react";

import classnames from "classnames";
import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Text from "lib/@cms/components/shared/Text";
import BodyText from "lib/@cms/components/shared/BodyText";
import Callout from "lib/@cms/components/cms/Callout";
import Divider from "lib/@cms/components/primitive/Divider";

function VisaDebitAccount() {
  return (
    <Page pathname="visa-debit-account">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENT}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradient[0]}
            />
            <Divider className="tw-my-7" />
            <div className="tw-container">
              <Text variant={Text.variant.SIMPLE}>{data.MetaData.description}</Text>
              <Divider className="tw-my-7" />

              <ListSection featuresSection={data.FeaturesV2} />

              {data.CalloutsData?.map((callout, index) => {
                return (
                  <React.Fragment key={`CalloutData-${index}`}>
                    <Divider className="tw-mt-20" />
                    <Callout
                      variant={Callout.variant.DATA}
                      title={callout.title}
                      description={callout.description}
                      items={callout.items}
                    />
                    <Divider className="tw-mt-48" />
                  </React.Fragment>
                );
              })}
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default VisaDebitAccount;

// --- Components ---

function ListSection({ featuresSection }) {
  return featuresSection?.map((item, index) => {
    const isLineSeparator = index === 0;
    const trasformationData = { ...item, list: item.descriptions, buttonName: item.buttonText };
    return (
      <React.Fragment key={`text_list_${index}`}>
        <BodyText data={trasformationData} variant={BodyText.variant.DECORATION_NONE} />
        <Divider
          className={classnames(
            "tw-my-7 sm:tw-my-14 ",
            isLineSeparator ? "tw-border tw-border-grayscale-6" : "",
          )}
        />
      </React.Fragment>
    );
  });
}
